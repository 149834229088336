var CandidatoManage = Vue.extend({

  components: {
    "vue-datetime-picker": DatePickerVue,
    "vue-select2": Select2Vue
  },

  data: function(){
      return {
        candidato: {
          fecha_nacimiento: null,
          idiomas: [],
          estudios: [],
          experiencias: []
        }
      }
  },

  computed: {},

  methods: {
  	addIdioma: function(){
  		this.candidato.idiomas.push({});
  	},
    removeIdioma: function(i){
      this.candidato.idiomas.splice(i, 1);
    },
    addEstudio: function(){
      this.candidato.estudios.push({inicio:null,fin:null});
    },
    removeEstudio: function(i){
      this.candidato.estudios.splice(i, 1);
    },
    addExperiencia: function(){
      this.candidato.experiencias.push({inicio:null,fin:null});
    },
    removeExperiencia: function(i){
      this.candidato.experiencias.splice(i, 1);
    }
  },

  created: function created() {
    console.log('created candidatoManage');
    if (window.laravel_obj) {
      this.$set('candidato', laravel_obj);
    }
  },

  ready: function ready() {}

});
