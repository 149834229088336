$(function(){

});

new Vue({

  el: '#frontend-vue',

  data:{

  },

  components: {
  	'candidato-manage': CandidatoManage,
    'proceso-seleccion': ProcesoSeleccion
  },

  created: function() {
	  console.log('created app general');
  },

  ready: function() {
  	console.log('ready app general');
  }

});