var ProcesoSeleccion = Vue.extend({

  components: {
  },

  data: function(){
      return {
        loading: true,
        loadingTinder: false
      }
  },

  props: {
    proceso_hash:{
      type: String,
      required: true,
      default:null
    },
    proceso_id:{
      type: String,
      required: true,
      default:null
    },
    candidatos:{
      type: Array,
      required: true,
      default:[]
    },
    estados:{
      type: Object,
      required: true,
      default:{}
    }
  },


  computed: {

  },

  methods: {
    
    aceptaCandidato: function(cp){
      console.log(cp);
      this.loadingTinder = true;
      this.makeCall({
        candidato_id:        cp.candidato.candidato_id,
        estado_candidato_id: cp.estado_candidato_id+1,
        proceso_id:          this.proceso_id,
        proceso_hash:        this.proceso_hash,
      },cp);
    },

    rechazaCandidato: function(cp){
      console.log(cp);
      this.loadingTinder = true;
      this.makeCall({
        candidato_id:        cp.candidato.candidato_id,
        estado_candidato_id: cp.estado_candidato_id-1,
        proceso_id:          this.proceso_id,
        proceso_hash:        this.proceso_hash,
      },cp);
    },

    makeCall: function(params,cp){

      Vue.http.get(window.BASE_URL+'/api/candidato/choose',{params:params}).then((response) => {

        this.loadingTinder = false;

        if(response.data.saved){
          cp.estado_candidato_id = params.estado_candidato_id;

        } else {
          alert(response.data.msg);
          
        }
        

      }, (response) => { 
          
        this.loadingTinder = false;
        console.error(response);

      });

    }

  },

  created: function() {
    console.log('proceso-seleccion created');
  },

  ready: function() {
    console.log('proceso-seleccion ready');
    console.log(this.estados);
    var that = this;
    setTimeout(function(){
      $('.carousel-inner .item').first().addClass('active');
      $('.carousel-indicators li').first().addClass('active');

      $('#candidatos-carousel').carousel({
        interval: false
      });

      that.loading = false;
    },5000);
  }

});

