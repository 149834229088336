var Select2Vue = Vue.extend({
  params: ['name'],
  replace: true,
  inherit: false,
  template: 
    '<div>'+
    ' <select class="form-control select2-vue vue-picker2" disabled="disabled" :name="name"></select>'+
    '</div>'
    ,
  props: {
    index: {
      required: true,
      twoWay: true
    },
    public: {
      required: false,
      type: Boolean,
      default: false
    },
    readonly: {
      required: false,
      twoWay: true
    },
    nullable:{
      required: false,
      type: Boolean,
      default: false
    },
    nullableText:{
      required: false,
      type: String,
      default: "Actualidad"
    },
    isNull:{
      required: false,
      type: Boolean,
      default: false
    },
    model: {
      required: true,
      twoWay: true
    },
    type: {
      type: String,
      required: false,
      default: undefined
    },
    name: {
      type: String,
      required: true,
      default: ""
    },
    onChange: {
      required: false,
      default: null
    }
  },
  beforeCompile: function() {
    if(this.index !== undefined){
      this.name = this.name.replace('INDEX',this.index+'');
    }
  },
  ready: function() {

    this.config = {
      theme: "bootstrap"
    };

    if(this.public){
      this.url = window.BASE_URL+'/api/select/';
    } else {
      this.url = window.BASE_URL+'/admin/api/select/';
    }

    var self = this;

    if(!this.type){
    
      this.config.placeholder = "-- Seleccionar --";
      this.initSelect2();

    }else{

      Vue.http.get(this.url+this.type).then((response) => {
          // success callback
          self.config.data = response.data;
          self.config.minimumInputLength = 0;
          this.config.placeholder = "-- Seleccionar " + self.type + " --";
//          self.config.data.unshift({id:"",text:'-- Seleccionar '+ self.type + ' --'});
          self.initSelect2();

      }, (response) => {
          // error callback
      });
    
    }

  },
  watch: {
    "model": function(val, oldVal) {
      //console.log('model change',val);
    },
    /*"isNull": function(val, oldVal) {
      if(this.nullable){
        if (val) {
            this.control.disable();
          this.model = null;
        } else {
          this.control.enable();
        }
      }
    }*/

  },
  methods: {
    initSelect2: function(){
        var self = this;
        this.control = $(this.$el).find('select')
          .prop("disabled", false)
          .select2(this.config)
          .on('change', function () {
              self.model = this.value;
          });


        this.control.val(this.model||0).trigger("change");

    }
  }
});